
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Accordion, AccordionDetails, AccordionSummary,  Box, Chip, CircularProgress, Divider, Grid, LinearProgress,  Stack, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React, { Fragment,  useState } from "react";
import YearMonthPicker from "../yearMonthPicker";
import MultipleSelectUnidade from "../multipleSelectCheck";
import AuthService from "../../service";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack";
import { format, lastDayOfMonth, subMonths } from "date-fns";
import { startOfMonth } from 'date-fns';
import { getAllLoadings, hasAnyLoading, setLoading } from "../../dashboard/component/loading/store";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { useSocket } from "../../utils/socket";

const LOADING_LABELS = {
    KPI_SALES: 'Vendas',
    KPI_PRODUCT: 'Produto',
    KPI_REALTIME_TODAY: 'Tempo real (hoje)',
    KPI_REALTIME_REALTIME: 'Tempo real',
    KPI_CHECKING: 'Controle',
    KPI_FINANCIAL: 'Financeiro',
    KPI_IDENTIFIER: "Comandas e mesas"
  };


export default function Filter() {
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [unidadeSelected, setUnidadeSelected] = useState([])
    const service = new AuthService()
    const unidadesByProfile = service.getProfile()?.unidadeList
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const allLoadings = useSelector((state) => getAllLoadings(state));
    const anyLoading = useSelector((state) => hasAnyLoading(state));
    const [otherLoading, setAnotherLoading] = useSocket(selectedDate)

    const handleChangeDateTo = e => {
        setSelectedDate(new Date(e));
    }
    const handleChangeSelectTo = (values) => {
        setUnidadeSelected(values.map(unidade => JSON.parse(unidade)))
    }

    const onClickBuscar = async () => {

            let status = await service.get('/redis/status', []);
            if(status[0] === "Não conectado"){
                enqueueSnackbar('Não foi possível buscar as informações, servidor Redis está offline', {
                    preventDuplicate: true,
                    variant: 'warning',
                })
                
                return
            }

            const idsUnidade = unidadeSelected.map(unidade => unidade.idUnidade).join(',');
            if (!idsUnidade) {
                enqueueSnackbar('Nenhuma unidade selecionada', {
                    preventDuplicate: true,
                    variant: 'warning',
                })

                return
            }

            const lastDayOfMonthFormatted = format(lastDayOfMonth(selectedDate), 'yyyy-MM-dd')
            const startOfMonthFormatted = format(startOfMonth(subMonths(selectedDate, 1)), 'yyyy-MM-dd');
            const today = format(selectedDate, 'yyyy-MM-dd');

            var yesterday = new Date(selectedDate);
            yesterday.setDate(selectedDate.getDate() - 1);
            yesterday = format(yesterday, 'yyyy-MM-dd');

            const params = {
                startDate: startOfMonthFormatted,
                endDate: lastDayOfMonthFormatted,
                today,
                yesterday,
                unidade: idsUnidade
            };

            //setLoading(true);
            
            dispatch(setLoading('KPI_SALES', true));
            dispatch({ type: 'KPI_SALES', kpiSales: [], loading: true, selectedDate });
            
            dispatch(setLoading('KPI_PRODUCT', true));
            dispatch({ type: 'KPI_PRODUCT', kpiProduct: [], selectedDate });
           
            dispatch(setLoading('KPI_REALTIME_TODAY', true));
            dispatch({ type: 'KPI_REALTIME', kpiToday: [], selectedDate });

            dispatch(setLoading('KPI_REALTIME_REALTIME', true));
            dispatch({ type: 'KPI_REALTIME', kpiRealTime: [], selectedDate });

            dispatch(setLoading('KPI_IDENTIFIER', true));
            dispatch({ type: 'KPI_IDENTIFIER', listIdentifier: [], selectedDate });
            
            dispatch(setLoading('KPI_CHECKING', true));
            dispatch({ type: 'KPI_CHECKING', kpiChecking: [], selectedDate });

            dispatch(setLoading('KPI_FINANCIAL', true));
            dispatch({ type: 'KPI_FINANCIAL', kpiFinancialPending: [], selectedDate });


            service.get('/sales/card', params)
            .then(result => {
                if (result != "LOADING"){
                    dispatch(setLoading('KPI_SALES', false));
                    dispatch({ type: 'KPI_SALES', kpiSales: result, selectedDate });
                }
            })

            service.get('/product/card', params)
            .then(result => {
                if (result != "LOADING"){
                    dispatch(setLoading('KPI_PRODUCT', false));
                    dispatch({ type: 'KPI_PRODUCT', kpiProduct: result, selectedDate });
                }
            });

            service.get('/financialMovement/pendingList', params)
            .then(result => {
                if (result != "LOADING"){
                    dispatch(setLoading('KPI_FINANCIAL', false));
                    dispatch({ type: 'KPI_FINANCIAL', kpiFinancialPending: result, selectedDate });
                }
            });

            //await service.get('/product/card', params);
            service.get('/realtime/today', params)
            .then(result => {
                if (result != "LOADING"){
                    dispatch(setLoading('KPI_REALTIME_TODAY', false));
                    dispatch({ type: 'KPI_REALTIME', kpiToday: result, selectedDate });
                }
            });

            service.get('/realtime/realtime', params)
            .then(result => {
                if (result != "LOADING"){
                    dispatch(setLoading('KPI_REALTIME_REALTIME', false));
                    dispatch({ type: 'KPI_REALTIME', kpiRealTime: result, selectedDate });
                }
            });

            service.get('/settings/identificador', params)
            .then(result => {
                if (result != "LOADING"){
                    dispatch(setLoading('KPI_IDENTIFIER', false));
                    dispatch({ type: 'KPI_IDENTIFIER', kpiChecking: result, selectedDate });
                }
            });

            service.get('/checking/list', params)
            .then(result => {
                if (result != "LOADING"){
                    dispatch(setLoading('KPI_CHECKING', false));
                    dispatch({ type: 'KPI_CHECKING', kpiChecking: result, selectedDate });
                }
            });

            localStorage.setItem('filter_bi', JSON.stringify(params))
    }

    return (
        <Box>
            <Grid container spacing={2} alignItems='center' direction='row'>
                <Grid item md={3} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <YearMonthPicker handleChangeTo={handleChangeDateTo} />
                    </LocalizationProvider>
                </Grid>

                <Grid item md={7}  xs={12} >
                    <MultipleSelectUnidade 
                        fullWidth="true"
                        unidadeList={unidadesByProfile || []} 
                        handleChangeTo={handleChangeSelectTo} />
                </Grid>

                <Grid item md={2} xs={12}>

                    <LoadingButton
                        variant="outlined"
                        onClick={onClickBuscar}
                        loadingIndicator="Buscando..."
                        fullWidth={true}
                        loadingPosition="center"
                        startIcon={<SearchIcon />}>
                        Buscar
                    </LoadingButton>

                </Grid>
            </Grid>

            {
                anyLoading && (
                <Box mt={2}>

                    <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    >
                    {anyLoading ? (
                        <Fragment>
                        <Box mr={1}>
                            <CircularProgress size={20}/> 
                        </Box>
                        Buscando dados... Esse processo pode levar alguns minutos.
                        </Fragment>
                    ) : (
                        "Dados carregados."
                    )}

                    

                    </AccordionSummary>
                    <AccordionDetails>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {Object.entries(allLoadings).map(([key, value]) => (
                                    <TableRow key={key}>
                                    <TableCell component="th" scope="row">
                                        {LOADING_LABELS[key]}
                                    </TableCell>
                                    <TableCell align="right">{value ? <LinearProgress color="secondary" /> : <CheckIcon color="success" /> }</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </AccordionDetails>
                </Accordion>
 
                </Box>
                )
            }

            <Box mt={2} mb={2}>
                <Stack
                    direction={'row'}
                    useFlexGap
                    flexWrap="wrap"
                    spacing={0.5}>
                    {unidadeSelected.map(unidade => (
                        <Chip key={unidade.idUnidade} size="small" label={unidade.nome} color="primary" variant="outlined" />
                    ))}
                </Stack>
            </Box>

            <Divider />
        </Box >
    );
}