export const initialKpiSales = () => {
    return ({
        listAllSales: [],
        currentMonthSales: [],
        lastMonthSales: [],
        loading: false,
    })
}

function groupByMonth(data) {
    const groupedData = {};

    data.forEach((item) => {
        const month = item.mes;
        if (!groupedData[month]) {
            groupedData[month] = {
                month: month,
                records: [],
            };
        }
        groupedData[month].records.push(item);
    });

    return Object.values(groupedData);
}


export const kpiSales = (currentState, action) => {
    let kpiSales = {
        listAllSales: [],
        currentMonthSales: [],
        lastMonthSales: [],
        loading: action.loading ? action.loading : currentState.loading,
    }

    const currentDate = new Date(action.selectedDate)

    kpiSales.selectedDate = new Date(action.selectedDate)
    kpiSales.currentDate = currentDate.getMonth() + 1
    kpiSales.currentMonth = currentDate.getMonth() + 1
    kpiSales.currentDay = currentDate.getDate()
    kpiSales.date = new Date()

    kpiSales.isCurrentMonth = kpiSales.currentMonth === kpiSales.date.getMonth() + 1

    kpiSales.listAllSales = action.kpiSales

    const groupedData = groupByMonth(kpiSales.listAllSales);

    kpiSales.currentMonthSales = groupedData.find(e => e.month === kpiSales.currentMonth)?.records
    kpiSales.lastMonthSales = groupedData.find(e => e.month !== kpiSales.currentMonth)?.records

    // console.log('kpiSales.listAllSales', kpiSales.listAllSales)

    return {
        ...currentState,
        kpiSales: kpiSales
    }
}
