"use client";

import {
    Grid,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels,
    Col
} from "@tremor/react";
import { useSelector } from 'react-redux';
import ListRealTime from "./list";
import { InfoRealTimeKPI } from "./helper";
import CardInfoToday from "./component/today";
import CardInfoRealtime from "./component/realtime";

export default function RealTime() {
    const kpiRealTime = useSelector((state) => state.kpiRealTime)
    const CardInfoRealTimeKPI = InfoRealTimeKPI(kpiRealTime)


    return (
        <TabGroup className="mt-0">
            <TabList>
                <Tab>Visão geral</Tab>
                <Tab>Listagem</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    {/* <Grid numItemsSm={1} numItemsMd={1} numItemsLg={2} className="gap-6 mt-6"> */}
                    {/* <CardInfoRealtime /> */}
                    {/* </Grid> */}

                    <Grid numItemsSm={1} numItemsMd={2} numItemsLg={2} className="gap-6 mt-6">
                        <Col>
                            <CardInfoRealtime />
                        </Col>

                        <Col>
                            <CardInfoToday {...CardInfoRealTimeKPI} />
                        </Col>
                    </Grid>
                </TabPanel>
                <TabPanel>
                    <ListRealTime data={kpiRealTime.listAllSalesToday || []} />
                </TabPanel>
            </TabPanels>
        </TabGroup >
    );
}