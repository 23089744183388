"use client";

import {
    Grid,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels
} from "@tremor/react";
import KpiCard from "../component/kpiCard"
import { useSelector } from 'react-redux';
import { Skeleton } from "@mui/material";
import { cumulativeSalesPerDateKPI, cumulativeSalesPerTypeOrderKPI, cumulativeSalesPerWeekKPI, orderAverageTicketKPI, orderCountKPI, orderTotalKPI, salesPerHourKPI, salesPerImportTypeKPI } from "./helper";
import KpiAreaChart from "../component/areaChart";
import KpiBarChart from "../component/barChart";
import ListSales from "./list";

export default function Sales() {
    const kpiSales = useSelector((state) => state.kpiSales)

    const cardOrderCountKPI = orderCountKPI(kpiSales)
    const cardOrderTotalKPI = orderTotalKPI(kpiSales)
    const cardOrderAverageTicketKPI = orderAverageTicketKPI(kpiSales)
    const chartCumulativeSalesPerDateKPI = cumulativeSalesPerDateKPI(kpiSales)
    const chartCumulativeSalesPerWeekKPI = cumulativeSalesPerWeekKPI(kpiSales)
    const chartCumulativeSalesPerTypeOrderKPI = cumulativeSalesPerTypeOrderKPI(kpiSales)
    const chartSalesPerHourKPI = salesPerHourKPI(kpiSales)
    const chartSalesPerImportTypeKPI = salesPerImportTypeKPI(kpiSales)

    return (
        <TabGroup className="mt-0">
            <TabList>
                <Tab>Visão geral</Tab>
                <Tab>Listagem</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    {kpiSales ?
                        <Grid numItemsSm={1} numItemsMd={2} numItemsLg={3} className="gap-6 mt-6">
                            <KpiCard {...cardOrderCountKPI} />
                            <KpiCard {...cardOrderTotalKPI} />
                            <KpiCard {...cardOrderAverageTicketKPI} />
                        </Grid>

                        :
                        <Grid numItemsSm={1} numItemsMd={2} numItemsLg={3} className="gap-6 mt-6">
                            <Skeleton variant="rounded" height={150} />
                            <Skeleton variant="rounded" height={150} />
                            <Skeleton variant="rounded" height={150} />
                        </Grid>
                    }

                    <div className="mt-6">
                        {kpiSales ?
                            <KpiAreaChart {...chartCumulativeSalesPerDateKPI} />

                            :

                            <Skeleton variant="rounded" height={400} />
                        }
                    </div>
                    <div className="mt-6">
                        {kpiSales ?
                            <Grid numItemsSm={1} numItemsMd={1} numItemsLg={2} className="gap-6 mt-6">
                                <KpiBarChart {...chartCumulativeSalesPerWeekKPI} />
                                <KpiAreaChart {...chartSalesPerHourKPI} />
                                <KpiBarChart {...chartCumulativeSalesPerTypeOrderKPI} />
                                <KpiBarChart {...chartSalesPerImportTypeKPI} />
                            </Grid>
                            :
                            <Grid numItemsSm={1} numItemsMd={1} numItemsLg={2} className="gap-6 mt-6">
                                <Skeleton variant="rounded" height={400} />
                                <Skeleton variant="rounded" height={400} />
                                <Skeleton variant="rounded" height={400} />
                                <Skeleton variant="rounded" height={400} />
                            </Grid>
                        }
                    </div>
                </TabPanel>
                <TabPanel>
                    <ListSales data={kpiSales.listAllSales || []} />
                </TabPanel>
            </TabPanels>
        </TabGroup>
    );
}