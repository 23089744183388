import { BarChart, Card, Title } from "@tremor/react";


export default function KpiBarChart(props) {
    const { caption, height } = props

    const dataFormatter = (number) => {
        const options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }

        return "R$ " + Intl.NumberFormat("pt-BR").format(number, options).toString();
    }

    const customClassName = `${height || 'h-72'} mt-4`

    return (

        <Card>
            <Title>{caption}</Title>
            <BarChart
                className={customClassName}
                layout={'horizontal'}
                noDataText="Nenhum dado encontrado"
                yAxisWidth={60}
                valueFormatter={dataFormatter}
                {...props}
            />
        </Card>
    );
}