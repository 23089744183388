import { roundToUp } from "round-to"
import { formatNumber, formatNumberToMM } from "../../../utils/number"
import { isMobile } from "react-device-detect";

var months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
];

var weeks = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "sexta-feira",
    "Sábado"
];


function countOfOrdersToDate(data, targetDate) {
    let count = 0;

    data?.forEach((sale) => {
        if (sale.dia <= targetDate) {
            count++;
        }
    });

    return count;
}

function sumValueOfOrdersToDate(data, targetDate) {
    let sum = 0;

    data?.forEach((sale) => {
        if (sale.dia <= targetDate) {
            sum += parseFloat(sale.valorTotal);
        }
    });

    return sum;
}

function getSalesTotalUntilDate(sales, targetDate) {

    let total = 0;

    sales.forEach((sale) => {
        // console.log(sale, targetDate)

        const saleDate = sale.day;
        if (saleDate <= targetDate) {
            total += parseFloat(sumTotalValue(sale.records));
        }
    });

    return total;
}

function getSalesTotalDate(sales, targetDate) {

    let total = 0;

    sales.forEach((sale) => {
        // console.log(sale, targetDate)

        const saleDate = sale.day;
        if (saleDate === targetDate) {
            total += parseFloat(sumTotalValue(sale.records));
        }
    });

    return total;
}

function sumTotalValue(data) {

    const total = data?.reduce((sum, sale) => {
        const saleTotal = sale.dataCancelamento? 0: parseFloat(sale.valorTotal);
        return sum + saleTotal;
    }, 0);

    return total || 0;
}


function groupByDate(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const day = item.dia;
        if (!groupedData[day]) {
            groupedData[day] = {
                day: day,
                records: [],
            };
        }
        groupedData[day].records.push(item);
    });

    return Object.values(groupedData);
}

function groupByWeek(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const week = item.diaSemana;
        if (!groupedData[week]) {
            groupedData[week] = {
                week: week,
                records: [],
            };
        }
        groupedData[week].records.push(item);
    });

    return Object.values(groupedData);
}
function groupByHour(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const hour = item.hora;
        if (!groupedData[hour]) {
            groupedData[hour] = {
                hour: hour,
                records: [],
            };
        }
        groupedData[hour].records.push(item);
    });

    return Object.values(groupedData);
}

function groupByImportType(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const importType = item.tipoPedidoDescricao;
        if (!groupedData[importType]) {
            groupedData[importType] = {
                importType: importType,
                records: [],
            };
        }
        groupedData[importType].records.push(item);
    });

    return Object.values(groupedData);
}

function groupByTypeOrder(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const typeOrder = item.tipoPedido;
        if (!groupedData[typeOrder]) {
            groupedData[typeOrder] = {
                typeOrder: typeOrder,
                records: [],
            };
        }
        groupedData[typeOrder].records.push(item);
    });

    return Object.values(groupedData);
}

/**
 * 
 * @param {*} sales 
 * @param {*} lastSales 
 * @returns { caption, metric, badgeDelta, progress, target, deltaType }
 *  
 */
export const orderCountKPI = (sales) => {
    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    const existsLastMonthSales = sales && sales.lastMonthSales && sales.lastMonthSales.length

    let result = {
        caption: 'Pedidos',
        metric: 0,
        badgeDelta: 0,
        progress: 0,
        target: 0,
        deltaType: 'moderateIncrease'
    }

    if (!existsCurrentMonthSales) {
        return result
    }

    const lengthCurrentSales = sales.currentMonthSales.length
    result.metric = isMobile ? formatNumberToMM(lengthCurrentSales) : formatNumber(lengthCurrentSales, 'decimal')

    if (existsLastMonthSales) {
        const lengthLastSales = sales.lastMonthSales?.length


        result.target = isMobile ? formatNumberToMM(lengthLastSales) : formatNumber(lengthLastSales, 'decimal')
        result.progress = roundToUp((lengthCurrentSales / lengthLastSales) * 100, 2)
        result.badgeDelta = roundToUp(((lengthCurrentSales - lengthLastSales) / lengthLastSales) * 100, 2)

        //para calcular percentual deve contar os pedidos do mês anterior até a data de "hoje", pois senão seria sempre vai ficar negativo o percentual
        if (sales.isCurrentMonth) {
            let salesByToday = countOfOrdersToDate(sales.lastMonthSales, sales.currentDay)
            result.badgeDelta = roundToUp(((lengthCurrentSales - salesByToday) / salesByToday) * 100, 2)
        }

    }

    // console.log('orderCountKPI - result', lengthCurrentSales, sales.currentDay, sales.isCurrentMonth)
    return result
}

export const orderTotalKPI = (sales) => {
    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    const existsLastMonthSales = sales && sales.lastMonthSales && sales.lastMonthSales.length


    let result = {
        caption: 'Valor total',
        metric: 0,
        badgeDelta: 0,
        progress: 0,
        target: 0,
        deltaType: 'moderateIncrease'
    }


    if (!existsCurrentMonthSales) {
        return result
    }

    const currentMonthValue = sumTotalValue(sales.currentMonthSales);
    result.metric = isMobile ? formatNumberToMM(currentMonthValue) : formatNumber(currentMonthValue, {style: 'currency'})

    if (existsLastMonthSales) {
        const lastMonthValue = sumTotalValue(sales.lastMonthSales);
        result.target = isMobile ? formatNumberToMM(lastMonthValue) : formatNumber(lastMonthValue, {style: 'currency'})

        result.progress = roundToUp((currentMonthValue / lastMonthValue) * 100, 2)
        result.badgeDelta = roundToUp(((currentMonthValue - lastMonthValue) / lastMonthValue) * 100, 2)


        //para calcular percentual deve contar os pedidos do mês anterior até a data de "hoje", pois senão seria sempre vai ficar negativo o percentual
        if (sales.isCurrentMonth) {
            let salesByToday = sumValueOfOrdersToDate(sales.lastMonthSales, sales.currentDay)


            result.badgeDelta = roundToUp(((currentMonthValue - salesByToday) / salesByToday) * 100, 2)
        }

    }

    // console.log('orderTotalKPI - result', result)


    return result
}

export const orderAverageTicketKPI = (sales) => {
    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    const existsLastMonthSales = sales && sales.lastMonthSales && sales.lastMonthSales.length


    let result = {
        caption: 'Ticket médio',
        metric: 0,
        badgeDelta: 0,
        progress: 0,
        target: 0,
        deltaType: 'moderateIncrease'
    }

    if (!existsCurrentMonthSales) {
        return result
    }

    const currentMonthLength = sales.currentMonthSales.length
    const currentMonthValue = sumTotalValue(sales.currentMonthSales);
    const currentMetric = currentMonthValue / currentMonthLength

    result.metric = formatNumber(roundToUp(currentMetric, 2), {style: 'currency'})

    if (existsLastMonthSales) {
        const lastMonthLength = sales.lastMonthSales.length
        const lastMonthValue = sumTotalValue(sales.lastMonthSales);
        const currentTarget = lastMonthValue / lastMonthLength

        result.target = formatNumber(roundToUp(currentTarget, 2), {style: 'currency'})
        result.badgeDelta = roundToUp(((currentMetric - currentTarget) / currentTarget) * 100, 2)
        result.progress = roundToUp((currentMetric / currentTarget) * 100, 2)
    }

    // //para calcular percentual deve contar os pedidos do mês anterior até a data de "hoje", pois senão seria sempre vai ficar negativo o percentual
    // if (sales.isCurrentMonth) {
    //     let salesByToday = sumValueOfOrdersToDate(sales.lastMonthSales, sales.currentDay)
    //     result.badgeDelta = roundToUp(((currentMetric - salesByToday) / salesByToday) * 100, 2)
    // }


    // console.log('orderTotalKPI - result', result)


    return result
}

export const cumulativeSalesPerDateKPI = (sales) => {
    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    // const existsLastMonthSales = sales && sales.lastMonthSales && sales.lastMonthSales.length

    // console.log('orderCountKPI -> sales', sales)

    let result = {
        caption: 'Vendas acumuladas por dia',
        data: [],
        index: "date",
        categories: [],
        colors: ["indigo", "cyan", "emerald"],
    }


    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const currentMonthSalesByDay = `Vendas diárias de ${currentMonthDescription}`
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription, currentMonthSalesByDay)

    const groupedDataCurrentMonth = groupByDate(sales.currentMonthSales)
    const groupedDataLastMonth = groupByDate(sales.lastMonthSales)

    let chartData = [];

    for (let day = 1; day <= 31; day++) {
        chartData.push({
            date: day,
            [lastMonthDescription]: getSalesTotalUntilDate(groupedDataLastMonth, day),
            [currentMonthDescription]: getSalesTotalUntilDate(groupedDataCurrentMonth, day),
            [currentMonthSalesByDay]: getSalesTotalDate(groupedDataCurrentMonth, day)
        });
    }

    result.data = chartData

    // console.log('cumulativeSalesPerDateKPI -> result', result)
    return result
}

export const cumulativeSalesPerWeekKPI = (sales) => {
    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    // const existsLastMonthSales = sales && sales.lastMonthSales && sales.lastMonthSales.length

    let result = {
        caption: 'Vendas por dia da semana',
        data: [],
        index: "week",
        categories: [],
        colors: ["indigo", "cyan"],
    }

    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByWeek(sales.currentMonthSales)
    const groupedDataLastMonth = groupByWeek(sales.lastMonthSales)

    // console.log('cumulativeSalesPerWeekKPI', groupedDataCurrentMonth, groupedDataLastMonth)

    function filterByWeek(data, week) {
        return data?.find(item => item?.week === week);
    }

    let chartData = [];

    for (let week = 0; week <= 6; week++) {
        chartData.push({
            week: weeks[week],
            [lastMonthDescription]: sumTotalValue(filterByWeek(groupedDataLastMonth, week)?.records),
            [currentMonthDescription]: sumTotalValue(filterByWeek(groupedDataCurrentMonth, week)?.records),
        });
    }

    result.data = chartData

    // console.log('cumulativeSalesPerDateKPI -> result', result)

    return result
}

export const cumulativeSalesPerTypeOrderKPI = (sales) => {
    
    let result = {
        caption: 'Vendas por tipo de pedido',
        data: [],
        index: "typeOrder",
        categories: [],
        colors: ["indigo", "cyan"],
        // layout: 'vertical',
    }

    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    
    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByTypeOrder(sales.currentMonthSales)
    const groupedDataLastMonth = groupByTypeOrder(sales.lastMonthSales)

    function filterByTypeOrder(data, typeOrder) {
        return data?.find(item => item?.typeOrder?.toUpperCase() === typeOrder?.toUpperCase());
    }

    function extractTypeOrders(data) {
        const typeOrders = data.map(item => item.typeOrder || 'Comanda');
        return typeOrders.filter((value, index) => typeOrders.indexOf(value?.toUpperCase()) === index);
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];
    
    const orderTypeList = extractTypeOrders(groupedData)
    
    let chartData = [];

    orderTypeList.forEach(type => {
        chartData.push({
            typeOrder: type,
            [lastMonthDescription]: sumTotalValue(filterByTypeOrder(groupedDataLastMonth, type)?.records),
            [currentMonthDescription]: sumTotalValue(filterByTypeOrder(groupedDataCurrentMonth, type)?.records),
        });
    })

    result.data = chartData

    // console.log('-> result', result)

    return result
}

export const salesPerHourKPI = (sales) => {
    let result = {
        caption: 'Vendas por hora',
        data: [],
        index: "hour",
        categories: [],
        colors: ["indigo", "cyan"],
    }

    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    
    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByHour(sales.currentMonthSales)
    const groupedDataLastMonth = groupByHour(sales.lastMonthSales)

    function filterByHour(data, hour) {
        return data?.find(item => item?.hour === hour);
    }

    let chartData = [];

    for (let hour = 0; hour <= 23; hour++) {
        chartData.push({
            hour,
            [lastMonthDescription]: sumTotalValue(filterByHour(groupedDataLastMonth, hour)?.records),
            [currentMonthDescription]: sumTotalValue(filterByHour(groupedDataCurrentMonth, hour)?.records),
        });
    }

    result.data = chartData

    // console.log('salesPerHourKPI -> result', result)

    return result
}

export const salesPerImportTypeKPI = (sales) => {
    let result = {
        caption: 'Vendas por canal',
        data: [],
        layout: 'vertical',
        index: "typeOrder",
        categories: [],
        colors: ["indigo", "cyan"],
        yAxisWidth: 90,
    }

    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length

    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1
    
    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByImportType(sales.currentMonthSales)
    const groupedDataLastMonth = groupByImportType(sales.lastMonthSales)

    function filterByImportType(data, importType) {
        return data?.find(item => item?.importType?.toUpperCase() === importType?.toUpperCase());
    }

    function extractImportType(data) {
        const importTypes = data.map(item => item.importType?.toUpperCase() || 'Comanda');

        return importTypes.filter((value, index) => importTypes.indexOf(value?.toUpperCase()) === index);
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];
    const importTypeList = extractImportType(groupedData)

    let chartData = [];

    importTypeList.forEach(type => {
        chartData.push({
            typeOrder: type,
            [lastMonthDescription]: sumTotalValue(filterByImportType(groupedDataLastMonth, type)?.records),
            [currentMonthDescription]: sumTotalValue(filterByImportType(groupedDataCurrentMonth, type)?.records),
        });
    })

    result.data = chartData

    return result
}