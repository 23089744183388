"use client";

import {
    Grid,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels,
} from "@tremor/react";
import { useSelector } from 'react-redux';
import { Skeleton } from "@mui/material";
import KpiBarChart from "../component/barChart";
import { CumulativeSalesPerStore } from "./helper";


export default function Franchise() {
    const kpiSales = useSelector((state) => state.kpiSales)

    const cumulativeSalesPerStoreKPI = CumulativeSalesPerStore(kpiSales)
    

    return (
        <div>
            <TabGroup className="mt-1">
                <TabList>
                    <Tab>Visão geral</Tab>
                    {/* <Tab>Listagem</Tab> */}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {kpiSales ?
                            <Grid numItemsSm={1} numItemsMd={1} numItemsLg={1} className="gap-6 mt-6">
                                <KpiBarChart {...cumulativeSalesPerStoreKPI} />
                            </Grid>

                            :
                            <Grid numItemsSm={1} numItemsMd={2} numItemsLg={3} className="gap-6 mt-6">
                                <Skeleton variant="rounded" height={150} />
                             
                            </Grid>
                        }
                    </TabPanel>
                    {/* <TabPanel>
                        <div className="mt-6">
                            <Card>
                                <div className="h-96" />
                            </Card>
                        </div>
                    </TabPanel> */}
                </TabPanels>
            </TabGroup>
        </div >
    );
}
