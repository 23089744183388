
var months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
];

function sumTotalValue(data) {

    const total = data?.reduce((sum, sale) => {
        const saleTotal = sale.dataCancelamento? 0: parseFloat(sale.valorTotal);
        return sum + saleTotal;
    }, 0);

    return total || 0;
}

function groupByStore(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const store = item.unidadeNome;
        if (!groupedData[store]) {
            groupedData[store] = {
                store: store,
                records: [],
            };
        }
        groupedData[store].records.push(item);
    });

    return Object.values(groupedData);
}


export const CumulativeSalesPerStore = (sales) => {
    let result = {
        caption: 'Vendas por unidade',
        data: [],
        index: "store",
        categories: [],
        colors: ["indigo", "cyan"],
        yAxisWidth: 90,
    }

    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length

    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1
    
    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByStore(sales.currentMonthSales)
    const groupedDataLastMonth = groupByStore(sales.lastMonthSales)

    function filterByStore(data, importType) {
        return data?.find(item => item?.store?.toUpperCase() === importType?.toUpperCase());
    }

    function extractStore(data) {
        const stores = data.map(item => item.store?.toUpperCase());

        return stores.filter((value, index) => stores.indexOf(value?.toUpperCase()) === index);
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];
    const storeList = extractStore(groupedData)


    let chartData = [];

    storeList.forEach(store => {
        chartData.push({
            store: store,
            [lastMonthDescription]: sumTotalValue(filterByStore(groupedDataLastMonth, store)?.records),
            [currentMonthDescription]: sumTotalValue(filterByStore(groupedDataCurrentMonth, store)?.records),
        });
    })

    result.data = chartData

    return result
}