import { roundTo } from "round-to";

var months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
];

function sumTotalValue(data) {

    const total = data?.reduce((sum, sale) => {
        const saleTotal = sale.somaNoTotal && sale.dataCancelamento ? 0 : parseFloat(sale.valorTotal);
        return sum + saleTotal;
    }, 0);

    return total || 0;
}

function sumQuantity(data) {

    const total = data?.reduce((sum, sale) => {
        const saleQuantity = sale.somaNoTotal && parseFloat(sale.quantidade);
        return sum + saleQuantity;
    }, 0);

    return total || 0;
}


function groupByProductGroup(data) {

    const groupedData = {};

    data?.forEach((item) => {
        const group = item.grupoProdutoDescricao;
        if (!groupedData[group]) {
            groupedData[group] = {
                group: group,
                records: [],
            };
        }
        groupedData[group].records.push(item);
    });

    return Object.values(groupedData);
}

function groupByProduct(data) {

    const groupedData = {};

    data?.forEach((item) => {
        const product = item.descricao;
        if (!groupedData[product]) {
            groupedData[product] = {
                product: product,
                records: [],
            };
        }
        groupedData[product].records.push(item);
    });

    return Object.values(groupedData);
}


export const CumulativeSalesByProductGroup = (sales) => {
    const existsCurrentMonthSales = sales && sales.currentMonthSalesProduct && sales.currentMonthSalesProduct.length
    let result = {
        caption: 'Top 10 Grupo de produto',
        data: [],
        index: "productGroup",
        categories: [],
        autoMinValue: true,
        colors: ["indigo", "cyan"],

    }

    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByProductGroup(sales.currentMonthSalesProduct)
    const groupedDataLastMonth = groupByProductGroup(sales.lastMonthSalesProduct)


    function filterByProductGroup(data, productGroup) {
        return data?.find(item => item?.group?.toUpperCase() === productGroup?.toUpperCase());
    }

    function extractProductGroup(data) {
        const groups = data.map(item => item.group?.toUpperCase());

        return groups.filter((value, index) => groups.indexOf(value?.toUpperCase()) === index);
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];
    const productGroupList = extractProductGroup(groupedData)

    let chartData = [];

    productGroupList.forEach(productGroup => {
        chartData.push({
            productGroup: productGroup,
            [lastMonthDescription]: sumTotalValue(filterByProductGroup(groupedDataLastMonth, productGroup)?.records),
            [currentMonthDescription]: sumTotalValue(filterByProductGroup(groupedDataCurrentMonth, productGroup)?.records),
        });
    })

    chartData.sort((a, b) => b[currentMonthDescription] - a[currentMonthDescription]);
    const extractedList = chartData.slice(0, 10);

    result.data = extractedList

    return result
}

export const CumulativeSalesByProduct = (sales) => {
    const existsCurrentMonthSales = sales && sales.currentMonthSalesProduct && sales.currentMonthSalesProduct.length
    let result = {
        height: 'h-[800px]',
        caption: 'Top produtos',
        data: [],
        yAxisWidth: 180,
        index: "description",
        categories: [],
        layout: 'vertical',
    }

    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByProduct(sales.currentMonthSalesProduct)
    const groupedDataLastMonth = groupByProduct(sales.lastMonthSalesProduct)


    function filterByProduct(data, product) {
        return data?.find(item => item?.product?.toUpperCase() === product?.toUpperCase());
    }

    function extractProduct(data) {
        const products = data.map(item => item.product?.toUpperCase());

        return [...new Set(products.flat())];
    }

    function extractStore(data) {
        const stores = data.map(item => item.records.map(rec => rec.unidadeName?.toUpperCase()));

        return [...new Set(stores.flat())];
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];

    const productList = extractProduct(groupedData)
    const storeList = extractStore(groupedData)
    
    let chartData = [];

    const totalCurrentMonth = sumTotalValue(sales.currentMonthSalesProduct)
    const totalLastMonth = sumTotalValue(sales.lastMonthSalesProduct)

    function calculateTotals(groupedData, product) {
        const totalsByUnidade = {};

        groupedData.filter(e => e.product === product).map(item => {
            item.records.map(record => {

                if (storeList.includes(record.unidadeName.toUpperCase())) {

                    if (totalsByUnidade[record.unidadeName] === undefined) {
                        totalsByUnidade[record.unidadeName] = 0;
                    }

                    totalsByUnidade[record.unidadeName] += parseFloat(record.valorTotal);
                }
            });
        });

        const resultList = Object.entries(totalsByUnidade).map(([unidadeName, total]) => ({ name: unidadeName, value: total }));
    
        const sortedResultList = resultList.sort((a, b) => b.value - a.value);

        return sortedResultList;
    }

    productList.forEach(product => {


        let metricLastMonth = sumTotalValue(filterByProduct(groupedDataLastMonth, product)?.records)
        let quantityLastMonth = sumQuantity(filterByProduct(groupedDataLastMonth, product)?.records)
        let metricCurrentMonth = sumTotalValue(filterByProduct(groupedDataCurrentMonth, product)?.records)
        let quantityCurrentMonth = sumQuantity(filterByProduct(groupedDataCurrentMonth, product)?.records)

        let targetLastMonth = roundTo((metricLastMonth / totalLastMonth) * 100, 2)
        let targetCurrentMonth = roundTo((metricCurrentMonth / totalCurrentMonth) * 100, 2)

        const resultListLast = calculateTotals(groupedDataLastMonth, product);
        const resultListCurrent = calculateTotals(groupedDataCurrentMonth, product);

        chartData.push({
            description: product,
            [lastMonthDescription]: { metric: metricLastMonth, delta: targetLastMonth, quantity: quantityLastMonth, cumulativeList: resultListLast },
            [currentMonthDescription]: { metric: metricCurrentMonth, delta: targetCurrentMonth, quantity: quantityCurrentMonth, cumulativeList: resultListCurrent }
        });
    })



    result.data = chartData

    return result
}


// function filterByStore(data, importType) {
//     return data?.find(item => item?.store?.toUpperCase() === importType?.toUpperCase());
// }

// function extractStore(data) {
//     const stores = data.map(item => item.store?.toUpperCase());

//     return stores.filter((value, index) => stores.indexOf(value?.toUpperCase()) === index);
// }

// const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];
//


// let chartData = [];

// storeList.forEach(store => {
//     chartData.push({
//         store: store,
//         [lastMonthDescription]: sumTotalValue(filterByStore(groupedDataLastMonth, store)?.records),
//         [currentMonthDescription]: sumTotalValue(filterByStore(groupedDataCurrentMonth, store)?.records),
//     });
// })