"use client";

import {
    Card,
    Grid,
    Title,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels,
    AreaChart
} from "@tremor/react";
import { useSelector } from 'react-redux';
import { Skeleton } from "@mui/material";
import { FinancialPaidDebitValueKPI, FinancialPendingCreditValueKPI, FinancialPendingDebitValueKPI, FinancialReceivedCreditValueKPI } from "./helper";
import SimpleKpiCard from "../component/simpleKpiCard";
import KpiCard from "../component/kpiCard";
import { isLoading } from "../component/loading/store";

export default function Financial() {
    const kpiFinancial = useSelector((state) => state.kpiFinancial)

    const loadingKPIFINANCIAL = useSelector((state) => isLoading(state, 'KPI_FINANCIAL'));
    
    const cardFinancialPendingCreditValueKPI = FinancialPendingCreditValueKPI(kpiFinancial)
    const cardFinancialPendingDebitValueKPI = FinancialPendingDebitValueKPI(kpiFinancial)
    
    // const kpiFinancialNotPending = useSelector((state) => state.kpiFinancialNotPending)
    // const cardFinancialPaidDebitValueKPI = FinancialPaidDebitValueKPI(kpiFinancial)
    // const cardFinancialReceivedCreditValueKPI = FinancialReceivedCreditValueKPI(kpiFinancialNotPending)

    return (
        <div>
            <TabGroup className="mt-1">
                <TabList>
                    <Tab>Visão geral</Tab>
                    <Tab>Listagem</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {
                            kpiFinancial && !loadingKPIFINANCIAL ?
                                <Grid numItemsSm={1} numItemsMd={2} numItemsLg={4} className="gap-6 mt-6">
                                    <KpiCard {...cardFinancialPendingCreditValueKPI} />
                                    <KpiCard {...cardFinancialPendingDebitValueKPI} />
                                    {/* <KpiCard {...cardFinancialPaidDebitValueKPI} /> */}
                                    {/* <KpiCard {...cardFinancialReceivedCreditValueKPI} /> */}
                                    {/* <SimpleKpiCard {...cardFinancialPendingDebitValueKPI} /> */}
                                    {/* <SimpleKpiCard {...cardFinancialPendingCreditValueKPI} />
                                    <SimpleKpiCard {...cardFinancialPendingCreditValueKPI} /> */}

                                </Grid>

                                :
                                <Grid numItemsSm={1} numItemsMd={2} numItemsLg={4} className="gap-6 mt-6">
                                    <Skeleton variant="rounded" height={150} />
                                    <Skeleton variant="rounded" height={150} />
                                    <Skeleton variant="rounded" height={150} />
                                    <Skeleton variant="rounded" height={150} />
                                </Grid>
                        }

                    </TabPanel>
                    <TabPanel>
                        <div className="mt-6">
                            <Card>
                                <div className="h-96" />

                                {kpiFinancial?.length}
                            </Card>
                        </div>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div >
    );
}