import { useState, React, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Sales from '../dashboard/sales';
import Financial from '../dashboard/financial';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import Filter from '../component/filter';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import Target from '../target';
import SideBarTop from '../appBar/sideBar';
import { useDispatch } from 'react-redux';
import Profile from '../appBar/profile';
import { isMobile } from 'react-device-detect';
import InventoryIcon from '@mui/icons-material/Inventory';
import StoreIcon from '@mui/icons-material/Store';
import Product from '../dashboard/product';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Franchise from '../dashboard/franchise';
import RealTime from '../dashboard/realTime';
import Checking from '../dashboard/checking';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AuthService from '../service';

const StatusRedis = (props) => {
  const service = new AuthService()
  const [status, setStatus] = useState()

  useEffect(() => {
    service.get('/redis/status', []).then(result => setStatus(result))
  }, [])

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      Status do cache: {status}
    </Typography>
  );
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://sischef.com/">
        sischef.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default function Home() {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(isMobile ? false : true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [menuItemSelected, setMenuItemSelected] = useState(0)
  const navigate = useNavigate();

  const getSelectMenu = (id) => {

    switch (id) {
      case 0:
        return <Sales />
      case 1:
        return <Financial />
      case 2:
        return <Product />
      case 3:
        return <Checking />
      case 4:
        return <Franchise />
      case 5:
        return <RealTime />
      case 6:
        return <Target />

      default:
        return

    }
  }
  const getSelectMenuDescription = (id) => {
    switch (id) {
      case 0:
        return 'Vendas'
      case 1:
        return 'Financeiro'
      case 2:
        return 'Produto'
      case 7:
        return 'Clientes'
      case 3:
        return 'Controle'
      case 4:
        return 'Unidades'
      case 5:
        return 'Hoje'
      case 6:
        return 'Settings'
      default:
        return ''

    }
  }

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT_USER' })

    localStorage.removeItem('id_token')
    localStorage.removeItem('_profile')

    navigate('/login');

    // console.log('saiu')
  }

  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
            align='left'
          >
            {getSelectMenuDescription(menuItemSelected)}
          </Typography>

          <IconButton color="inherit">
            <Tooltip title='Atualizar informações'>
              <RefreshIcon />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            px: [1],
          }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 8px 8px',
          }}>
            <SideBarTop />

            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          {open &&
            <Profile />
          }
        </Toolbar>
        <Divider />
        <List component="nav">
          <ListItemButton key={5} onClick={() => setMenuItemSelected(5)}>
            <ListItemIcon><AccessTimeIcon /></ListItemIcon>
            <ListItemText primary={'Tempo real'} />
          </ListItemButton>

          <ListItemButton key={0} onClick={() => setMenuItemSelected(0)}>
            <ListItemIcon><PointOfSaleIcon /></ListItemIcon>
            <ListItemText primary={'Vendas'} />
          </ListItemButton>

          <ListItemButton disabled={false} key={1} onClick={() => setMenuItemSelected(1)}>
            <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
            <ListItemText primary={'Financeiro'} />
          </ListItemButton>

          <ListItemButton disabled={false} key={2} onClick={() => setMenuItemSelected(2)}>
            <ListItemIcon><InventoryIcon /></ListItemIcon>
            <ListItemText primary={'Produto'} />
          </ListItemButton>

          <ListItemButton disabled={true} key={7} onClick={() => setMenuItemSelected(2)}>
            <ListItemIcon><RecentActorsIcon /></ListItemIcon>
            <ListItemText primary={'Cliente'} />
          </ListItemButton>

          <ListItemButton disabled={false} key={3} onClick={() => setMenuItemSelected(3)}>
            <ListItemIcon><KeyboardCommandKeyIcon /></ListItemIcon>
            <ListItemText primary={'Controle'} />
          </ListItemButton>

          <ListItemButton disabled={false} key={4} onClick={() => setMenuItemSelected(4)}>
            <ListItemIcon><StoreIcon /></ListItemIcon>
            <ListItemText primary={'Franquia'} />
          </ListItemButton>

          {/* <ListItemButton disabled={true} key={6} onClick={() => setMenuItemSelected(5)}>
            <ListItemIcon><TrackChangesIcon /></ListItemIcon>
            <ListItemText primary={'Metas'} />
          </ListItemButton> */}


          <ListItemButton key={20} onClick={handleLogout}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={'Sair'} />
          </ListItemButton>

          <Divider />

          {open &&
            <StatusRedis sx={{ pt: 2 }} />
          }

          {open &&
            <Copyright sx={{ pt: 2 }} />
          }
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <main className="px-6 py-6">
          <Box pb={5}>
            <Filter />

          </Box>


          {getSelectMenu(menuItemSelected)}

        </main>
      </Box>
    </Box >
  );
}