import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TableHead } from '@mui/material';
import { currencyFormat, formatNumber } from '../../../utils/number';
import { dateFormat } from '../../../utils/date';

const columns = [
    { id: 'idUnidade', label: 'ID Unidade', minWidth: 80 },
    { id: 'unidadeName', label: 'Unidade Nome', minWidth: 80 },
    { id: 'idProduto', label: 'ID Produto', minWidth: 80 },
    { id: 'produtoNome', label: 'Produto Nome', minWidth: 200 },
    { id: 'quantidade', label: 'Quantidade', minWidth: 80 },
    { id: 'dataLancamento', label: 'Data Lançamento', minWidth: 50, align: 'right' },
    { id: 'dataExclusao', label: 'Data Cancelamento', minWidth: 50, align: 'right' },
    { id: 'nomeUsuarioExclusao', label: 'Usuário Cancelamento', minWidth: 100, align: 'right' },
    { id: 'valorTotal', label: 'Valor Total', minWidth: 100, align: 'right' },
    { id: 'observacao', label: 'Observação', minWidth: 200, align: 'right' },
]

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function ListChecking({ data }) {

    // console.log('KpiTable', data)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        // <Card>
        <TableContainer elevation={0} component={Paper}>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {(rowsPerPage > 0
                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : data
                    ).map((row, index) => (
                        <TableRow key={index}>
                            <TableCell  >
                                {row.idUnidade}
                            </TableCell>
                            <TableCell align="left" component="th">
                                {row.unidadeName}
                            </TableCell>
                            <TableCell align="left">
                                {row.idProduto}
                            </TableCell>
                            <TableCell align="left">
                                {row.produtoNome}
                            </TableCell>
                            <TableCell align="right">
                                {formatNumber(row.quantidade, { style: 'decimal', minimumFractionDigits: '3' })}
                            </TableCell>
                            <TableCell align="right">
                                {dateFormat(row.dataLancamento, { pattern: 'dd/MM/yyyy HH:mm:ss' })}
                            </TableCell>
                            <TableCell align="right">
                                {dateFormat(row.dataExclusao, { pattern: 'dd/MM/yyyy HH:mm:ss' })}
                            </TableCell>
                            <TableCell align="left">
                                {row.nomeUsuarioExclusao}
                            </TableCell>
                            <TableCell align="right">
                                {currencyFormat(row.valorTotal, { simbol: '' })}
                            </TableCell>
                            <TableCell align="left">
                                {row.observacao}
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={8} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                            colSpan={8}
                            count={data?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage='linhas por página'
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'linhas por página',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>

                </TableFooter>

            </Table>
        </TableContainer>
        // </Card>
    )
}