"use client";

import {
    Grid,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels,
} from "@tremor/react";
import { useSelector } from 'react-redux';
import { Skeleton } from "@mui/material";
import { CumulativeSalesByProduct, CumulativeSalesByProductGroup } from "./helper";
import ListProduct from "./list";
import KpiBarChart from "../component/barChart";
import ListProgressBar from "../component/listProgressBar";


export default function Product() {
    const kpiProduct = useSelector((state) => state.kpiProduct)

    const cumulativeSalesByProductGroupKPI = CumulativeSalesByProductGroup(kpiProduct)
    const cumulativeSalesByProductKPI = CumulativeSalesByProduct(kpiProduct)

    return (
        <div>
            <TabGroup className="mt-1">
                <TabList>
                    <Tab>Visão geral</Tab>
                    <Tab>Listagem</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {/* { caption, metric, badgeDelta, progress, target, deltaType } */}
                        {kpiProduct ?
                            <Grid className="gap-6 mt-6">
                                <KpiBarChart {...cumulativeSalesByProductGroupKPI} />
                                <ListProgressBar {...cumulativeSalesByProductKPI} />
                            </Grid>

                            :

                            <Grid className="gap-6 mt-6">
                                <Skeleton variant="rounded" height={150} />
                                <Skeleton variant="rounded" height={150} />
                            </Grid>
                        }
                    </TabPanel>
                    <TabPanel>
                        <ListProduct data={kpiProduct.currentMonthSalesProduct || []} />
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div >
    );
}
