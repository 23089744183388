import { roundToUp } from "round-to";
import numeral from 'numeral';


export const hashCode = (str) => {
    return str.split('').reduce((prevHash, currVal) =>
        (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
}

export const roundUp = (value, casas) => {
    let valueRoundUp = roundToUp(value, casas + 1)
    return parseFloat(valueRoundUp.toFixed(casas))
}


export const roundDown = (value, casas) => {
    let valueRoundUp = roundToUp(value, casas + 1)
    return parseFloat(valueRoundUp.toFixed(casas))
}

export const currencyFormat = (value, options) => {

    let { simbol } = { simbol: 'R$', ...options }

    let formated = null
    if (!value && 0 !== value) {
        formated = '0.00'
    } else {
        let val = parseFloat(value)
        formated = roundUp(val, 2).toFixed(2).replace('.', ',');
    }


    return `${simbol} ${formated}`
}

export const formatNumber = (value, style) => {
    // "R$ 123.356,65" = {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(123356.65)}
    let param = {
        locales: 'pt-BR',
        options: {
            currency: 'BRL',
            useGrouping: true,
            ...style,
        }
    }

    if (value !== null && value !== undefined) {
        return new Intl.NumberFormat(param.locales, param.options).format(value)
    } else {
        return value
    }
}


export function formatNumberToMM(number) {
    return numeral(number || 0).format('0.0a');
}


