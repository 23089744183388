"use client";

import {
    Grid,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels
} from "@tremor/react";
import { useSelector } from 'react-redux';
import { Skeleton } from "@mui/material";
import { CheckingCountKPI, CheckingValueKPI, CumulativeCheckingByDateKPI, CumulativeCheckingByProductKPI, CumulativeCheckingByUser, CumulativeCheckingByUserKPI } from "./helper";
import ComparativeCardKpi from "../component/comparativeCardKpi";
import ListProgressBar from "../component/listProgressBar";
import GroupProductByDate from "./component/groupProductByDate";
import ListChecking from "./list";

export default function Checking() {
    const kpiChecking = useSelector((state) => state.kpiChecking)

    const cardCheckingCountKPI = CheckingCountKPI(kpiChecking)
    const cardCheckingValueKPI = CheckingValueKPI(kpiChecking)
    const cardCumulativeCheckingByUserKPI = CumulativeCheckingByUserKPI(kpiChecking)
    const cardCumulativeCheckingByProductKPI = CumulativeCheckingByProductKPI(kpiChecking)

    return (
        <TabGroup className="mt-0">
            <TabList>
                <Tab>Visão geral</Tab>
                <Tab>Listagem</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    {kpiChecking ?
                        <Grid numItemsSm={1} numItemsMd={2} numItemsLg={2} className="gap-6 mt-6">
                            <ComparativeCardKpi {...cardCheckingCountKPI} />
                            <ComparativeCardKpi {...cardCheckingValueKPI} />

                        </Grid>

                        :
                        <Grid numItemsSm={1} numItemsMd={2} numItemsLg={2} className="gap-6 mt-6">
                            <Skeleton variant="rounded" height={150} />
                            <Skeleton variant="rounded" height={150} />
                        </Grid>
                    }

                    <div className="mt-6">
                        {kpiChecking ?
                            <Grid numItemsSm={1} numItemsMd={1} numItemsLg={1} className="gap-6 mt-6">
                                <ListProgressBar {...cardCumulativeCheckingByUserKPI} />
                                <ListProgressBar {...cardCumulativeCheckingByProductKPI} />
                            </Grid>

                            :

                            <Skeleton variant="rounded" height={400} />
                        }
                    </div>
                </TabPanel>
                <TabPanel>
                    <ListChecking data={kpiChecking.listAllChecking|| []} />
                </TabPanel>
            </TabPanels>
        </TabGroup>
    );
}